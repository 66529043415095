import React from "react";
import HomeTemplate from "./HomeTemplate.js"

const Landingpage = () => {
  return (
    <div className="pa3">
        <div>   
            <HomeTemplate/>
        </div>
    </div>
  );
}

export default Landingpage;
